import React from 'react';
import styles from './headerImage.module.scss';
import { Link, graphql } from 'gatsby';
import { Parallax } from 'react-parallax';
import VisibilitySensor from '../../VisibilitySensor';
import { Spring } from 'react-spring/renderprops';
import scrollTo from 'gatsby-plugin-smoothscroll';

export default ({
	title,
	image,
	subTitle,
	icon,
	vimeoUrl,
	strokeDasharray
}) => (
	<div className={`module ${styles.headerImage} ${image ? '' : styles.overlay}`}>
		<div className={styles.wrapper}>
			<div className={styles.image} video={vimeoUrl ? 'true' : 'false'}>
				<div className={styles.lines}>
					<span />
					<span />
				</div>
				{image ?
					<Parallax style={{ position: 'initial', overflow: 'visible' }}
						renderLayer={percentage => (
							<div className={styles.image} style={{
								top: percentage * 140
							}}>
								<img src={image.fluid.src} alt={image.title} title={image.description} />
							</div>
							)}
					/> : ''
				}
				{vimeoUrl ?
					<div className={styles.video}>
						<iframe src={`${vimeoUrl}?background=1&autoplay=1&loop=1&title=0&byline=0&portrait=0&muted=1`}></iframe>
					</div>
					: ''
				}
				<div className={styles.headerContent}>
					<svg height="100" width="1000" stroke="#ffffff" strokeWidth="1.5" className={styles.textLine}>
					<VisibilitySensor once={false}>
					{({ isVisible }) => (
						<Spring
							delay={400}
							to={{
								animationPlayState: isVisible ? "running" : "paused"
							}}
						>
							{
								props =>
								
									<text x="0" y="110" fill="none" strokeDasharray={strokeDasharray} fontSize = "100" style={props} >{title}</text>
									
								}
						</Spring>
					)}
				</VisibilitySensor>
				</svg>
					{subTitle ? <span className="subtitle">{subTitle}</span> : ''}
				</div>
			</div>
		</div>
		<div className={styles.redIcon}>Scroll<a onClick={() => scrollTo('#intro')}></a></div>
	</div>
)

export const query = graphql`
	fragment HeaderImage on ContentfulBlockHeaderImage {
		id
		title
		strokeDasharray
		subTitle
		image {
			title
			description
			fluid(quality: 100, maxWidth: 2000, toFormat: JPG) {
				src
			}
		}
		vimeoUrl
		icon {
			file {
				url
			}
		}
	}
`;