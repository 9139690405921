import React, { Component } from 'react';
import propTypes from 'prop-types';
import VSensor from 'react-visibility-sensor';

class VisibilitySensor extends Component {
	constructor(props) {
		super(props);
		this.state = {
			active: true
		};
	}

	render() {
		const { active, isVisible } = this.state;
		const { once, children, ...otherProps } = this.props;
		return (
			<VSensor
				active={active}
				onChange={isVisible => {
					once &&
						isVisible &&
						this.setState({ active: false }, () =>
							''
						);
				}}
				{...otherProps}
			>
				{({ isVisible }) => children({ isVisible })}
			</VSensor>
		);
	}
}

VisibilitySensor.propTypes = {
	children: propTypes.func.isRequired,
	once: propTypes.bool
};

VisibilitySensor.defaultProps = {
	once: true,
	minTopValue: 250
};

export default VisibilitySensor;
